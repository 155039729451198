<template>
  <div>
    <ModalCreateBookingTerm
      v-if="showCreateTerm"
      :showModal="showCreateTerm"
      :onClickCancel="hideModal"
      :channelId="channelId"
      @newTermCreated="getBookingTerms"
    />

    <ModalDeleteBookingTerm
      v-if="showDeleteTerm"
      :showModal="showDeleteTerm"
      :onClickCancel="hideModal"
      :bookingTerm="bookingTerm"
      @termDeleted="getBookingTerms"
    />

    <ModalActivateBookingTerm
      v-if="showActivateTerm"
      :showModal="showActivateTerm"
      :onClickCancel="hideModal"
      :bookingTerm="bookingTerm"
      @termActivated="getBookingTerms"
    />

    <ui-page-hero :title="$t('Views.Terms.BookingTerms.Main_Title')" />

    <section class="section is-small">
      <div class="has-margin-bottom">
        <Message :message="$t('Views.Terms.BookingTerms.Message_Info')" />
      </div>
      <div
        class="actionBar has-background-white-ter has-padding has-margin-bottom"
      >
        <div class="level">
          <div class="level-left"></div>
          <div class="level-right">
            <a @click="setShowCreateTerm" class="has-icon">
              <span class="icon is-small">
                <font-awesome-icon :icon="['fas', 'plus']" />
              </span>
              <span>{{
                $t('Views.Terms.BookingTerms.Button_CreateTerm')
              }}</span>
            </a>
          </div>
        </div>
      </div>
    </section>

    <table class="table is-fullwidth is-striped">
      <thead>
        <tr>
          <th>{{ $t('Views.Terms.BookingTerms.TableHeader_Version') }}</th>
          <th>{{ $t('Views.Terms.BookingTerms.TableHeader_CreatedOn') }}</th>
          <th>{{ $t('Views.Terms.BookingTerms.TableHeader_Status') }}</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="term in bookingTerms" :key="term.Id">
          <td>
            <router-link
              :to="{
                name: 'bookingTermDetail',
                params: { termId: term.Id },
              }"
            >
              {{ term.Version }}
            </router-link>
          </td>
          <td>{{ term.CreatedOn | convertTimeStampToIsoDateTimeString }}</td>
          <td>
            <a @click="setShowActivateTerm(term)">
              <span v-if="term.Status === -1" class="tag">
                {{ $t('Views.Terms.BookingTerms.Tag_Archived') }}</span
              ></a
            >
            <span v-if="term.Status === 0" class="tag is-warning"
              ><a @click="setShowActivateTerm(term)">
                {{ $t('Views.Terms.BookingTerms.Tag_Draft') }}</a
              ></span
            >
            <span v-if="term.Status === 1" class="tag is-success">
              {{ $t('Views.Terms.BookingTerms.Tag_Active') }}</span
            >
          </td>
          <td class="no-wrap">
            <router-link
              :to="{
                name: 'bookingTermDetail',
                params: { termId: term.Id },
              }"
            >
              <span v-if="term.Status == 0" class="icon mr-1">
                <font-awesome-icon :icon="['fas', 'edit']" />
              </span>
            </router-link>

            <a @click="setShowDeleteTerm(term)">
              <span v-if="term.Status === 0" class="icon">
                <font-awesome-icon
                  :icon="['fas', 'trash-alt']"
                  style="color:red"
                />
              </span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import termsProvider from '@/providers/terms'

const ModalCreateBookingTerm = () =>
  import('@/components/Terms/ModalCreateBookingTerm')
const ModalDeleteBookingTerm = () =>
  import('@/components/Terms/ModalDeleteBookingTerm')
const ModalActivateBookingTerm = () =>
  import('@/components/Terms/ModalActivateBookingTerm')
const Message = () =>
  import(/* webpackChunkName: "reservations-list" */ '@/components/UI/Message')

export default {
  components: {
    ModalCreateBookingTerm,
    ModalDeleteBookingTerm,
    ModalActivateBookingTerm,
    Message,
  },

  props: {},

  data() {
    return {
      bookingTerms: [],
      channelId: Number(this.$route.params.channelId),
      isLoading: false,
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,
      selectedBookingTerm: null,
      showCreateTerm: false,
      showDeleteTerm: false,
      showActivateTerm: false,
      bookingTerm: {},
    }
  },

  computed: {},

  created() {
    this.getBookingTerms()
  },

  methods: {
    getBookingTerms() {
      let self = this

      if (!self.isLoading) {
        self.isLoading = true
        termsProvider.methods
          .getBookingTerms(this.channelId, this.locationId)
          .then((response) => {
            if (response.status === 200) {
              self.bookingTerms = response.data
            }
          })
          .finally(() => {
            self.isLoading = false
          })
      }
    },

    setShowCreateTerm() {
      this.showCreateTerm = true
    },

    setShowDeleteTerm(term) {
      this.showDeleteTerm = true
      this.bookingTerm = term
    },

    setShowActivateTerm(term) {
      this.showActivateTerm = true
      this.bookingTerm = term
    },

    hideModal() {
      this.showCreateTerm = false
      this.showDeleteTerm = false
      this.showActivateTerm = false
      this.bookingTerm = null
    },
  },
}
</script>

<style></style>
