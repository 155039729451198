var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showCreateTerm)?_c('ModalCreateBookingTerm',{attrs:{"showModal":_vm.showCreateTerm,"onClickCancel":_vm.hideModal,"channelId":_vm.channelId},on:{"newTermCreated":_vm.getBookingTerms}}):_vm._e(),(_vm.showDeleteTerm)?_c('ModalDeleteBookingTerm',{attrs:{"showModal":_vm.showDeleteTerm,"onClickCancel":_vm.hideModal,"bookingTerm":_vm.bookingTerm},on:{"termDeleted":_vm.getBookingTerms}}):_vm._e(),(_vm.showActivateTerm)?_c('ModalActivateBookingTerm',{attrs:{"showModal":_vm.showActivateTerm,"onClickCancel":_vm.hideModal,"bookingTerm":_vm.bookingTerm},on:{"termActivated":_vm.getBookingTerms}}):_vm._e(),_c('ui-page-hero',{attrs:{"title":_vm.$t('Views.Terms.BookingTerms.Main_Title')}}),_c('section',{staticClass:"section is-small"},[_c('div',{staticClass:"has-margin-bottom"},[_c('Message',{attrs:{"message":_vm.$t('Views.Terms.BookingTerms.Message_Info')}})],1),_c('div',{staticClass:"actionBar has-background-white-ter has-padding has-margin-bottom"},[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"}),_c('div',{staticClass:"level-right"},[_c('a',{staticClass:"has-icon",on:{"click":_vm.setShowCreateTerm}},[_c('span',{staticClass:"icon is-small"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'plus']}})],1),_c('span',[_vm._v(_vm._s(_vm.$t('Views.Terms.BookingTerms.Button_CreateTerm')))])])])])])]),_c('table',{staticClass:"table is-fullwidth is-striped"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('Views.Terms.BookingTerms.TableHeader_Version')))]),_c('th',[_vm._v(_vm._s(_vm.$t('Views.Terms.BookingTerms.TableHeader_CreatedOn')))]),_c('th',[_vm._v(_vm._s(_vm.$t('Views.Terms.BookingTerms.TableHeader_Status')))]),_c('th',[_vm._v(" ")])])]),_c('tbody',_vm._l((_vm.bookingTerms),function(term){return _c('tr',{key:term.Id},[_c('td',[_c('router-link',{attrs:{"to":{
              name: 'bookingTermDetail',
              params: { termId: term.Id },
            }}},[_vm._v(" "+_vm._s(term.Version)+" ")])],1),_c('td',[_vm._v(_vm._s(_vm._f("convertTimeStampToIsoDateTimeString")(term.CreatedOn)))]),_c('td',[_c('a',{on:{"click":function($event){return _vm.setShowActivateTerm(term)}}},[(term.Status === -1)?_c('span',{staticClass:"tag"},[_vm._v(" "+_vm._s(_vm.$t('Views.Terms.BookingTerms.Tag_Archived')))]):_vm._e()]),(term.Status === 0)?_c('span',{staticClass:"tag is-warning"},[_c('a',{on:{"click":function($event){return _vm.setShowActivateTerm(term)}}},[_vm._v(" "+_vm._s(_vm.$t('Views.Terms.BookingTerms.Tag_Draft')))])]):_vm._e(),(term.Status === 1)?_c('span',{staticClass:"tag is-success"},[_vm._v(" "+_vm._s(_vm.$t('Views.Terms.BookingTerms.Tag_Active')))]):_vm._e()]),_c('td',{staticClass:"no-wrap"},[_c('router-link',{attrs:{"to":{
              name: 'bookingTermDetail',
              params: { termId: term.Id },
            }}},[(term.Status == 0)?_c('span',{staticClass:"icon mr-1"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'edit']}})],1):_vm._e()]),_c('a',{on:{"click":function($event){return _vm.setShowDeleteTerm(term)}}},[(term.Status === 0)?_c('span',{staticClass:"icon"},[_c('font-awesome-icon',{staticStyle:{"color":"red"},attrs:{"icon":['fas', 'trash-alt']}})],1):_vm._e()])],1)])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }