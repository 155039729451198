import axios from "axios";
import store from "@/store";

export default {
  methods: {
    async getTermById(termId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/term/${termId}`,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      );
      return response;
    },

    async getLatestTerms(channelId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/term/channel/${channelId}/latest`,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      );
      return response;
    },

    async getChannelTerms(channelId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/term/channel/${channelId}`,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      );
      return response;
    },

    /** 
     * Get all booking terms of a location for a certain channel
     * 
     */
    async getBookingTerms(channelId, locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/term/booking`,
        {
          params: {
            channelId: channelId,
            locationId: locationId
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          },
        }
      )
      return response
    },

    async createBookingTerm(term) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/term/booking`,
        JSON.stringify(term),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async updateBookingTerm(term) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/term/booking`,
        JSON.stringify(term),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async deleteTerm(termId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/term/${termId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async updateActivateTerm(termId) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/term/${termId}/activate`,
        {},
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },
  }
};
